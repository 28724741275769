import React from 'react'
import IPR from '../../assets/images/IPR1.jpg'
import '../../css/Ipr.css'
import { Helmet } from 'react-helmet';
import { BiCheck } from "react-icons/bi";
import ClientLogohead from '../Home/ClientLogohead';
import { Divider } from '@mui/material';
import iprser from '../../assets/images/iprser.jpg'
import ipr from '../../assets/images/ipr_1.png'
import guarantee from '../../assets/images/guarantee.png'
import customer from '../../assets/images/customer.png'
import globe from '../../assets/images/globe.png'
import efficiency from '../../assets/images/efficiency.png'
import parentser from '../../assets/images/patent_ser.png'
import trademark from '../../assets/images/trademark.png'
import copyright from '../../assets/images/copyright.png'
import trade from '../../assets/images/trade.png'
import choose from '../../assets/images/iprchoose.png'
import HomeContact from '../Home/HomeContact';
import {Link} from 'react-router-dom';

function Ipr() {
    const guarantee ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599914/rio_main_website/ul8cvelysvblm5ythm6d.png"
    const globe ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599914/rio_main_website/lyoy6b3svzex610pegab.png"
    const efficiency ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599908/rio_main_website/s1prob6dpmhthr5rqehb.png"
    const customer ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599903/rio_main_website/kimi6krekhbi9jsdk5ko.png"
    const IPR ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599921/rio_main_website/rgwpvhh91ae9a3yhvbpo.png"
    const trademark ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599945/rio_main_website/wnlhgbcvyv62vo1jbya7.png"
    const trade ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599945/rio_main_website/kvrazaltgvcprmamewwz.png"
    const ipr ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599921/rio_main_website/v3tllmzmekvmtqpdbreq.png"
    const choose ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599893/rio_main_website/pocl8dnm0u6c6fcmwa2m.webp"
    const copyright ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599901/rio_main_website/df6kig2gw6sar0rmgscs.png"
    const parentser ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599929/rio_main_website/eghltsb273irnjqmj03s.png"
    return (
    <div>
        <Helmet>
        <title>Intellectual Property Rights Services | RioBizSols 
        </title>
        <meta name="description" content="RioBizSols offers expert services in intellectual property rights, including trademarks, trade secrets, patents, and copyrights protection." />
        <meta name="keywords" content="intellectual property rights, trade mark, trade secrets, patents, copy rights" />
      </Helmet>
        <div className="ipr-banner">
            <img src={IPR} alt="IPR" className='ipr-img'/>
            <h3 className="ipr_banner_sub_title">Protect Your Intellectual Property with Confidence</h3>
            <h1 className="ipr_banner_title">Safeguard your Innovations & Creativity</h1>
            <Link to='/contact'  style={{textDecoration:'none', color:'White'}}>
            <buttom className="ipr_buttom">Let's Contact <BiCheck className='ipr_icon1'/></buttom></Link>
        </div>
        <ClientLogohead/>
        
        <div className="container">
        <hr className='b-line' />
        <div>
           <h2  className="ipr_service_title">Why Choose the IP Rights Services</h2> 
           <p  className="ipr_service_para">At RIO, we understand the value of your ideas, innovations, and creative works. Our mission is to provide you with comprehensive intellectual property (IP) solutions that safeguard your rights and maximize the value of your intangible assets. Discover how Intellectual Property Rights Services are important for your business</p>
        </div>
        </div>
        <div className="container_ipr">
            <img src={ipr} alt="ipr"  className='ipr_ser_img'/>
            <div className="container">
              <div className="container_ipr1">
                <img src={guarantee} alt="guarantee"  className='ipr_icon'/>

                <div className="ipr_text_column">
              
               <h1>Expert Guidance</h1>
            <p >
             Experienced IP attorneys and professionals specializing in various aspects of intellectual property law hold deep understanding of the legal landscape, and provide strategic guidance tailored to unique business needs and goals.
            </p>
               <hr/>    
            
          </div>
            </div>   
             <div className="container_ipr1">
                <img src={customer} alt="customer"  className='ipr_icon'/>

                <div className="ipr_text_column">
           <div className="container">
           <h1>Customized Solutions</h1>
            <p>
            Every client and every IP portfolio is unique. That's why a personalized approach to every case is important to address specific challenges and objectives.
            </p>
             <hr/> 
           </div>
            
          </div>
            </div> 
             <div className="container_ipr1">
                <img src={efficiency} alt="efficiency"  className='ipr_icon'/>

                <div className="ipr_text_column">
            <h1>Comprehensive Services</h1>
            <p>
            From patent and trademark registration to IP enforcement and litigation support, a full range of IP Rights services is meant to meet all intellectual property needs.
            </p>
            <hr/> 
          </div>
            </div> 
            <div className="container_ipr1">
                <img src={globe} alt="globe"  className='ipr_icon'/>

                <div className="ipr_text_column">
            <h1>Global Reach</h1>
            <p>
            In today's interconnected world, protecting your IP rights across borders is essential. IPR service providers have global network of partners and associates, and can help you secure protection for your intellectual property assets in multiple jurisdictions around the world.
            </p>
           <hr/> 
            
          </div>
            </div> 
            </div>
        </div>
        <div className="container">
            <h2 className='ipr_service'>
            IPR Services
            </h2> 
        </div>
        <div className="container">
            <div className="container_ipr">
                <div className="ipr_text">
                    <h2>Patent Services</h2>
                    <p>Protect your innovations and inventions with our comprehensive patent services. Our team of experienced patent attorneys specializes in drafting, filing, and prosecuting patent applications across various industries. From conducting prior art searches to managing patent portfolios, we provide strategic guidance every step of the way to ensure your inventions receive the protection they deserve.</p>
                </div>
                 <img src={parentser} alt="parentser" className='ipr_text_img' /> 
            </div>

        </div>

        <div className="container">
            <div className="container_ipr_ser">
                
                <img src={trademark} alt="trademark" className='ipr_text_img' />
                <div className="ipr_text">
                    <h2>Trademark Services</h2>
                    <p>Safeguard your brand identity and reputation with our comprehensive trademark services. Whether you're launching a new brand or protecting an existing one, our team of trademark experts is here to help you navigate the intricacies of trademark law and registration.</p>
                </div>
            </div>

        </div> 

        <div className="container">
            <div className="container_ipr_ser">
                
               
                <div className="ipr_text">
                    <h2>Copyright Services</h2>
                    <p>Preserve your creative works and artistic expressions with our comprehensive copyright services. Whether you're a writer, artist, musician, or software developer, we can help you protect your original works and enforce your copyright rights.</p>
                </div>
                <img src={copyright } alt="copyright" className='ipr_text_img' />
            </div>

        </div>
        
        <div className="container">
            <div className="container_ipr_ser">
                
            <img src={trade} alt="trade" className='ipr_text_img' />
                <div className="ipr_text">
                    <h2>Trade Secret Protection</h2>
                    <p>Protect your valuable business information and confidential know-how with our trade secret protection services. Our team helps clients identify, safeguard, and enforce their trade secrets to maintain their competitive advantage and business success.</p>
                </div>
                
            </div>

        </div>
<div className='ipr-choose'>
    <img src={choose} alt="choose"  className='ipr-choose-img'/>
    <h2 className='ipr_choose_title'>Why Choose RIO?</h2>
    <p className='ipr_choose_para'>IP Rights Services is dedicated to helping individuals and businesses protect and maximize the value of their intellectual property assets. With our expertise, commitment to excellence, and client-focused approach, we've earned a reputation as a trusted partner in IP protection and enforcement. Whether you're an individual inventor, a startup, or a multinational corporation, our team of experts is here to help you navigate the complex world of IP rights with ease and confidence.</p>
</div>
<HomeContact/>

    </div>
  )
}

export default Ipr