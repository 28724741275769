import React from 'react'
import '../../../css/salemanagement.css'
import sale from '../../../assets/images/smodoo.png'
import crm from '../../../assets/images/crm.jpg'
import ClientLogohead from '../../Home/ClientLogohead'
import point from '../../../assets/images/point.jpg'
import rental from '../../../assets/images/rental.jpg'
import sale1 from '../../../assets/images/sales.jpg'
import ContactFrom from '../../Services/ContactFrom'
import { Helmet } from 'react-helmet';

function SaleManagement() {
  const sale ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599939/rio_main_website/v5ha0qg7heof4bacgpbq.png"
  const crm="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599903/rio_main_website/rsnw90izrfqeghgjreex.jpg"
  const rental="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599933/rio_main_website/ifuxc4hlliuzebhwdiwf.jpg"
  const sale1="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599928/rio_main_website/fedjzdl1abyhmq2woxln.jpg"
  const point="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599930/rio_main_website/aqvkxzymigq4w0v2l2qo.jpg"
  return (
    <div>
      <Helmet>
        <title>Odoo ERP Sales Management - Rio Bizsols Pvt Ltd</title>
        <meta name="description" content="Optimize sales processes with Rio Bizsols Pvt Ltd's Odoo ERP sales management solutions. Drive growth and efficiency in your business operations.
" />
        <meta name="keywords" content="" />
      </Helmet>
      <div className="sale_container_bg">
      <div className="sale_banner">
      <img src={sale} alt="sale" className='sale_banner_img'/>
      <div className="sale_text_col">
      <h1 className='sale_banner_title'>Experience the Future of
      Business with RIO</h1>
      <h3 className='sale_banner_subtitle'>Your Partner in Cutting-Edge Odoo ERP Solutions.</h3>
                   <p>As a premier Odoo ERP development company, RIO specializes in crafting robust and bespoke business solutions tailored to our client's unique needs. Trust us to optimize your business processes, enhance productivity, and propel your organization to new heights with our state-of-the-art Odoo ERP implementation.</p>     
        </div>
        </div>

      </div>
      <ClientLogohead/>
        <div className='container'>
          <hr/>
        </div>
        <div className="container">
        <div className="sale_banner1">
        <img src={crm} alt="crm"  className='sale_image' />
        <div className="sale_text_col1">
          <h2>CRM</h2>
          <p>Streamline your sales processes, improve efficiency, and drive business growth with our advanced CRM solutions tailored to your business. RIO offers comprehensive Odoo CRM solutions to empower your business with efficient customer relationship management capabilities. Our expert team utilizes the powerful features of Odoo to deliver customized CRM solutions tailored to your specific business needs. Partner with RIO for Odoo CRM solutions and unlock the full potential of your customer relationships!</p>
        </div> 
        </div>
        </div>
        <div className='container'>
          <hr/>
        </div>
        <div className="container">
        <div className="sale_banner1">
        <img src={point} alt="point" className='sale_image' />
        <div className="sale_text_col1">
          <h2>Point of Sales</h2>
          <p>RIO delivers robust Odoo Point of Sale (POS) solutions tailored to enhance the efficiency and effectiveness of your retail operations. Our expert team harnesses the advanced capabilities of Odoo to provide seamless POS solutions that streamline transactions, manage inventory. and optimize customer experiences. With RIO's Odoo POS solutions, you can transform your retail operations with a powerful, flexible, and intuitive POS system!</p>
        </div> 
        </div>
        </div>
        <div className='container'>
          <hr/>
        </div>
        <div className="container">
        <div className="sale_banner1">
        <img src={rental} alt="rental" className='sale_image' />
        <div className="sale_text_col1">
          <h2>Rental</h2>
          <p>A comprehensive Odoo rentals management solution is all you need to streamline the process of renting out equipment, vehicles, properties, and other assets. Leveraging the advanced capabilities of Odoo, RIO provides tailored solutions that optimize rental operations, improve efficiency, and enhance customer satisfaction.
          RIO's Odoo rentals management solutions are designed to help you maximize asset utilization, boost revenue, and foster business growth within the rental industry.</p>
        </div> 
        </div>
        </div>
        <div className='container'>
          <hr/>
        </div>
        <div className="container">
        <div className="sale_banner1">
        <img src={sale1} alt="sales" className='sale_image' />
        <div className="sale_text_col1">
          <h2>Sales</h2>
          <p>RIO delivers comprehensive Odoo sales management solutions designed to streamline your sales processes, optimize efficiency, and drive revenue growth. Leveraging the powerful features of Odoo, we provide tailored solutions that empower your sales team to close deals faster and deliver exceptional customer experiences.</p>
          <p>To summarize, our advanced solutions help streamline operations, enhance collaboration, and ensure exceptional customer experiences that differentiate you from the competition.</p>
        </div> 
        </div>
        </div>
        <div className="web_bg">
            <h2 className='web_bg_title'>With a track record of successful
            implementations and satisfied clients</h2>
            <p className='web_bg_para'>RIO is trusted by businesses worldwide to deliver exceptional Odoo solutions.

Don't just take our word for it—hear directly from our satisfied clients.</p>
        </div>
        <div>
            <h3 className='web_contact_title'>Ready to transform your business with RIO's
            Odoo development services?</h3>
            <p className='web_contact_para'>Contact us today for a free consultation and discover how we can help you achieve your business goals.

</p>
            <ContactFrom/>
        </div>

    </div>
  )
}

export default SaleManagement