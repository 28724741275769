// import React from 'react'
// import '../../css/Clientlogo.css'
// import Kumaraguru from '../../assets/images/kumaraguru.jpg';
// import Amazon from '../../assets/images/Amazon.jpg';
// import Aplus from '../../assets/images/Aplus.jpg';
// import Dare2escape from '../../assets/images/Dare2escape.jpg';
// import khadheejah from '../../assets/images/khadheejah.jpg';
// import Kmdecors from '../../assets/images/Km-Decors.jpg';
// import Shobha from '../../assets/images/Shobha.jpg';
// import studieo7 from '../../assets/images/Logo-6.png';
// import studio11 from '../../assets/images/Logo-7.png';
// import hydro from '../../assets/images/Logo-3.png';
// import flipkart from '../../assets/images/Flipkart.jpg'
// import waffel from '../../assets/images/1-Waffels-min-1.jpg';
// import rotol from '../../assets/images/2-Rotolo-min-1.jpg';
// import plush from '../../assets/images/3-Plush-min-1.jpg';
// import grace1 from '../../assets/images/5-Grace-min-1.jpg';
// import bloom from '../../assets/images/6-Bloom-min-1.jpg';
// import ee from '../../assets/images/6-EE-min-1.jpg';
// import sk from '../../assets/images/7-SK-min-1.jpg';
// import raajis from '../../assets/images/9-Raajis-min-1.jpg';


// function ClientLogohead() {
//   return (
//     <div className='client-logo'>
//         <h1 className='client'>Our Clients</h1>
//         <div className='logo'>
//         <div className='logo-slid'>
//         <img src={Kumaraguru} alt="rio_kumaraguru_logo" />
//             <img src={Amazon} alt="rio_amazon_logo"/>
//             <img src={Aplus} alt="rio_aplus_logo"/>
//             <img src={Dare2escape} alt="rio_dare_logo" />
//             <img src={khadheejah} alt="rio_khadheejah_logo" />
//             <img src={Kmdecors} alt="rio_km_logo" />
//             <img src={Shobha} alt="rio_shobho_logo" />
//             <img src={studieo7} alt="rio_studieo_logo" />
//             <img src={studio11} alt="rio_studio11_logo" />
//             <img src={hydro} alt="rio_hydro_logo" />
//             <img src={flipkart} alt="rio_flipkart_logo" />
//             <img src={waffel} alt="rio_waffel_logo" />
//             <img src={rotol} alt="rio_rotol_logo" />
//             <img src={plush} alt="rio_plush_logo" />
//             <img src={grace1} alt="rio_grace_logo" />
//             <img src={bloom} alt="rio_bloom_logo" />
//             <img src={ee} alt="rio_ee_logo" />
//             <img src={sk} alt="rio_sk_logo" />
//             <img src={raajis} alt="rio_raajis_logo" />
//         </div>
//         </div>
       
        
//         </div>
//   )
// }

// export default ClientLogohead


import React from 'react'
import '../../css/Clientlogo.css'
import Kumaraguru from '../../assets/images/kumaraguru.jpg';
import Amazon from '../../assets/images/Amazon.jpg';
import Aplus from '../../assets/images/Aplus.jpg';
import Dare2escape from '../../assets/images/Dare2escape.jpg';
import khadheejah from '../../assets/images/khadheejah.jpg';
import Kmdecors from '../../assets/images/Km-Decors.jpg';
import Shobha from '../../assets/images/Shobha.jpg';
import studieo7 from '../../assets/images/studieo7.jpg';
import studio11 from '../../assets/images/Logo-7.png';
import hydro from '../../assets/images/Logo-3.png';
import flipkart from '../../assets/images/Flipkart.jpg'
import waffel from '../../assets/images/1-Waffels-min-1.jpg';
import rotol from '../../assets/images/2-Rotolo-min-1.jpg';
import plush from '../../assets/images/3-Plush-min-1.jpg';
import grace from '../../assets/images/5-Grace-min-1.jpg';
import bloom from '../../assets/images/6-Bloom-min-1.jpg';
import ee from '../../assets/images/6-EE-min-1.jpg';
import sk from '../../assets/images/7-SK-min-1.jpg';
import raajis from '../../assets/images/9-Raajis-min-1.jpg';



function Clientlogohead() {
  const Kumaraguru = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599923/rio_main_website/cm2ual5i2rb60xpdvo2f.jpg"
  const Amazon = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599891/rio_main_website/wqnu2tv7fqca4xzlzfny.jpg"
  const Aplus ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599892/rio_main_website/emji2okoikkynjs5gkxi.jpg"
  const Dare2escape ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599904/rio_main_website/byfeygcgtqndlktuerbj.jpg"
  const khadheejah ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599922/rio_main_website/o261jmycfmgsrp8jatpp.jpg"
  const Kmdecors = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599923/rio_main_website/zf5eo70focqreqzrial1.jpg"
  const Shobha = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599937/rio_main_website/tjnywqleyex72rxpfogo.jpg"
  const studieo7 ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599925/rio_main_website/tirnxnww9qmnxcfnv4nb.png"
  const studio11 = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599926/rio_main_website/kahnwjqxt0a3qesf23hw.png"
  const hydro = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599925/rio_main_website/cscpsjpnozomodirhjgx.png"
  const flipkart ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599913/rio_main_website/xcoihvjmf4jctk88uryc.jpg"
  const waffel = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599890/rio_main_website/cqakmrqlbjeqqjhvfu0c.jpg"
  const rotol ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599890/rio_main_website/bz2qqssyxzfciep8yfpv.jpg"
  const plush ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599890/rio_main_website/qafpvzgvch2iep6vlloa.jpg"
  const grace ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599891/rio_main_website/rxsl535dhbrolpuzayjn.jpg"
  const bloom ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599891/rio_main_website/ronnusvbk5w3ecgzlcly.jpg"
  const ee ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599891/rio_main_website/vtxc4mp0ijrs9k3nklro.jpg"
  const sk ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599891/rio_main_website/pzxugkk0rf8h8htl5irt.jpg"
  const raajis ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599891/rio_main_website/mopvg37qvlk6xyvkrlom.jpg"
  return (
    <div className='client-logo'>
        <h1 className='client'>Our Clients</h1>
        <div className='logo'>
        <div className='logo-slid'>
            <img src={Kumaraguru} alt="rio_kumaraguru_logo" />
            <img src={Amazon} alt="rio_amazon_logo"/>
            <img src={Aplus} alt="rio_aplus_logo"/>
            <img src={Dare2escape} alt="rio_dare_logo" />
            <img src={khadheejah} alt="rio_khadheejah_logo" />
            <img src={Kmdecors} alt="rio_km_logo" />
            <img src={Shobha} alt="rio_shobho_logo" />
            <img src={studieo7} alt="rio_studieo_logo" />
            <img src={studio11} alt="rio_studio11_logo" />
            <img src={hydro} alt="rio_hydro_logo" />
            <img src={flipkart} alt="rio_flipkart_logo" />
            <img src={waffel} alt="rio_waffel_logo" />
            <img src={rotol} alt="rio_rotol_logo" />
            <img src={plush} alt="rio_plush_logo" />
            <img src={grace} alt="rio_grace_logo" />
            <img src={bloom} alt="rio_bloom_logo" />
            <img src={ee} alt="rio_ee_logo" />
            <img src={sk} alt="rio_sk_logo" />
            <img src={raajis} alt="rio_raajis_logo" />

        </div>
        </div>
       
        
        </div>
  )
}

export default Clientlogohead