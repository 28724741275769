const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
    //   import('web-vitals').then(({ getFID, getFCP, getLCP, getTTFB }) => {
        
    //     getFID(onPerfEntry);
    //     getFCP(onPerfEntry);
    //     getLCP(onPerfEntry);
    //     getTTFB(onPerfEntry);
    //   });
    }
  };
  
  export default reportWebVitals;
  