import React, { useEffect, useRef } from 'react';
import '../../css/about.css'
import about from '../../assets/images/About-us-3.jpg'

const About = () => {
  const about ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599892/rio_main_website/rzllor2rlfpunfelj6o0.jpg"
  const imgRef = useRef(About); 

  useEffect(() => {
    const handleScroll = () => {
      if (imgRef.current) {
        const scrollY = window.scrollY; 
        const rotation = scrollY * 0.1;
        imgRef.current.style.transform = `rotate(${rotation}deg)`; 
      }
    };

    window.addEventListener('scroll', handleScroll); 

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="about-banner">
        <h1 className="about-title">About Us!</h1>
        <div className="aboutusimg">
          <img
            className="spin-Image"
            src={about}
            alt="spin-image"
            ref={imgRef}
          />
        </div>
      </div>
      
      <div className="heading_about">
        <h3>We are RIO bizsols,</h3>
      </div>
      <div className="content_about">
        <p>
          Your go-to gurus for all things staffing? Check marketing? Double check. We do it all, saving you time, money, and headaches.
          Think of us as your Business’s secret weapon, helping you find the right people and making your brand the talk of the town.
          We are excited to be part of your success story. With a team that's as skilled as it is friendly, 
          we are always well prepared with a bunch of creative people and endless cups of chai to make your life easier and your business better.
          We are here to make your business journey smoother, faster, and a whole lot more fun.
        </p>
      </div>
      <div className="heading_about">
        <h3>Customer Delight</h3>
      </div>
      <div className="content_about">
        <p>
          You talk, We listen. Your goals are our goals, and we won’t rest until you are thrilled with the results.
          We go the extra mile to ensure you are not just satisfied but delighted with our services.
          From our first conversation to the successful completion of your project, 
          we are here to support you with enthusiasm and expertise.
          We have helped loads of businesses shine, and we are ready to do the same for you.
        </p>
      </div>
    </div>
  );
}

export default About;