import React from 'react'
import { BiCheck } from "react-icons/bi";
import appcons from '../../../assets/images/App-cons.png'
import ClientLogohead from '../../Home/ClientLogohead';
import app1 from '../../../assets/images/app-1.png'
import Enhance from '../../../assets/images/Enhanced-User-Experience-1.png'
import cost from '../../../assets/images/Cost-Savings.png'
import competitive from '../../../assets/images/Competitive-Advantage.png'
import peace from '../../../assets/images/Peace-of-Mind.png'
import '../../../css/maintenance.css'
import bug from '../../../assets/images/Bug-Fixes.jpg'
import performance from '../../../assets/images/Performance-Monitoring-Optimization.jpg'
import Feature from '../../../assets/images/Feature-Enhancements.jpg'
import securityaudit from '../../../assets/images/Security-Audits-Updates.jpg'
import compatibility from '../../../assets/images/Compatibility.jpg'
import continuous from '../../../assets/images/Continuous.jpg'
import dedicated from '../../../assets/images/Dedicated.jpg'
import data from '../../../assets/images/Data.jpg'
// import uiux from "../../assets/images/uiuxdesign-1.png"
// import host from "../../assets/images/host-1-1.png"
// import maintenance from "../../assets/images/maintenace-1-1.png"
// import webanalytics from "../../assets/images/Web-Analytics-1-1.png"
// import webdeve from "../../assets/images/webdev-appli.png"
import { Link } from 'react-router-dom'
import { FiArrowUpRight } from "react-icons/fi";
import Expertise from '../../../assets/images/Expertise-1.png'
import Proactive from '../../../assets/images/Proactive-Monitoring.png'
import Regular from '../../../assets/images/Regular-Updates-Optimization.png'
import securitycompliance from '../../../assets/images/Security-Compliance.png'
import Scalability from '../../../assets/images/Scalability.png'
import ContactFrom from '../../Services/ContactFrom';
import { Helmet } from 'react-helmet';


const Maintenance = () => {
  const Proactive="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599931/rio_main_website/rsdkpzcwcsro9sslvhhg.png"
  const Regular="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599932/rio_main_website/hbxgy5uaypq2v7rstgkg.png"
  const Scalability ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599935/rio_main_website/jnvvln1t5jtbjikylagv.png"
  const Expertise ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599911/rio_main_website/qu59glkbi71djwrfykbr.png"
  const securitycompliance ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599935/rio_main_website/ripqgtpz5ws53k1jkifd.png"
  const data ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599904/rio_main_website/u4z76724rphnqbzzfppr.jpg"
  const dedicated ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1733122597/Dedicated_r68mfh.jpg"
  const continuous="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1733122521/Continuous_pfyiyt.jpg"
  const compatibility="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1733122466/Compatibility_aomy2m.jpg"
  const securityaudit ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599935/rio_main_website/myfvigsyrdavycadok77.jpg"
  const Feature ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599912/rio_main_website/nniskzfwrwbonlgtlyxd.jpg"
  const performance="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1733122226/Performance-Monitoring-Optimization_g7uicr.jpg"
  const competitive ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1733122126/Competitive-Advantage_otlh6n.png"
  const bug ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599896/rio_main_website/rs7qnxh2z9retqvsekb9.jpg"
  const app1="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599893/rio_main_website/rbczxcikca8nqwcssxdu.png"
  const cost ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599902/rio_main_website/wafusrkjv6dym7gxtyrc.png"
 const Enhance ="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599909/rio_main_website/a1u2zu4ump91r0nneouh.png"
  const peace="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599929/rio_main_website/xhwxm36sbb1amysl1dkt.png"
  return (
    <div>
      <Helmet>
        <title>Maintenance & Post Warranty Support | RioBizSols 
        </title>
        <meta name="description" content="RioBizSols provides top-notch post warranty support and IT maintenance services. Trust our third-party IT maintenance for all your hardware needs." />
        <meta name="keywords" content="Post Warranty Support, it maintenance support, third party it maintenance, it hardware maintenance companies, maintenance support services" />
      </Helmet>
        <div className='sale_container_bg'>
            <div className="sale_banner">
            <img src={app1} alt="app" className='sale_banner_img2'  />

            <div className="sale_text_col">
            <h1 className='sale_banner_title'>Elevate Your App's
Lifespan and Performance</h1>
            
            <p>RIO's Maintenance & Post-Warranty Support services are designed to keep your app running smoothly, securely, and efficiently long after its initial launch. Whether you need bug fixes, performance optimizations, security updates, or ongoing technical support, RIO has you covered. Let's elevate your app's lifespan together!</p> 
            <Link to='/contact'><button className='brand_button'>Contact us<BiCheck className='ipr_icon1'/>

</button></Link>
            
            
            </div>
            </div>
        </div>
        <ClientLogohead/>
        <div className="wave-container1">
    <div className='wave-head'><h2 className='sale_banner_title'>Why invest in Maintenance & Post-warranty
    Support?</h2>
    {/* <p>Cross-platform app development allows you to create applications that work seamlessly across various operating systems such as iOS, Android, and Windows, using a single codebase. This approach not just saves time and resources but also ensures a consistent user experience across different devices.</p> */}
    </div>
   
      <div className="wave-content">
        <div className="wave-img">
          <div className="wave-imgs-content">
            <img src={Enhance} alt="enhanced" />
            <h4 className='wave-imgs-content-text'>Enhanced User Experience</h4>
            <p className='wave-imgs-content-text'>A well-maintained app delivers a superior user experience, fostering loyalty and positive reviews.</p>
          </div>
          <div className="wave-imgs-content">
             <img src={cost} alt="costsaving" />
             <h4 className='wave-imgs-content-text'>Cost <br/>Savings</h4>
             <p className='wave-imgs-content-text'>Proactive maintenance helps identify and address issues early, preventing costly downtime and emergency fixes.</p>
          </div>
          <div className="wave-imgs-content">
             <img src={competitive} alt="competitive" />
             <h4 className='wave-imgs-content-text'>Competitive Advantage</h4>
             <p className='wave-imgs-content-text'>By staying up-to-date and secure, your app maintains a competitive edge in the market, attracting and retaining users.</p>
          </div>
          <div className="wave-imgs-content">
             <img src={peace} alt="peace" />
             <h4 className='wave-imgs-content-text'>Peace of Mind</h4>
             <p className='wave-imgs-content-text'>Leave the technical complexities to us while you focus on your core business objectives, knowing your app is in capable hands.</p>
          </div>
          
        </div>
      </div>
    
      
  </div>
  <div className="mid-content">
          <div className="app-head-line">
          Services We Offer
          </div>
         
          </div>

          <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={bug} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      <div >
        <h2 className='wave-imgs-content-head'>Bug Fixes & Issue
        Resolution</h2>
        <p className='wave-imgs-content-text1'>
        Swift identification and resolution of bugs, glitches, and technical issues to ensure uninterrupted performance.</p>

<p className='wave-imgs-content-text1'>Thorough debugging processes to address both minor inconveniences and critical errors promptly.</p>
      
      </div>

      
    </div>

    <div className="app-container">
      

      <div >
        <h2 className='wave-imgs-content-head1'>Performance
Monitoring &
Optimization</h2>
        <p className='wave-imgs-content-textright'>
        Continuous monitoring of your app's performance metrics, including speed, responsiveness, and resource usage.</p>

<p className='wave-imgs-content-textright'>Proactive optimization strategies to enhance user experience and streamline operations for peak efficiency.

</p>
      
      </div>
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={performance} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      
    </div>
    <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={Feature} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      <div >
        <h2 className='wave-imgs-content-head'>Feature
        Enhancements</h2>
        <p className='wave-imgs-content-text1'>
        Collaborative brainstorming and implementation of new features and functionalities to keep your app fresh and competitive.</p>

<p className='wave-imgs-content-text1'>Seamless integration of user feedback and market trends to prioritize feature updates that resonate with your audience.</p>
      
      </div>

      
    </div>
    <div className="app-container">
      

      <div >
        <h2 className='wave-imgs-content-head1'>Security Audits &
Updates
</h2>
        <p className='wave-imgs-content-textright'>
        Comprehensive security audits to identify vulnerabilities and fortify your app against potential threats and breaches.</p>

<p className='wave-imgs-content-textright'>Timely implementation of security patches, encryption protocols, and authentication mechanisms to safeguard user data and privacy.
</p>
      
      </div>
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={securityaudit} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      
    </div>
    <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={compatibility} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      <div >
        <h2 className='wave-imgs-content-head'>Compatibility Checks
        & Updates</h2>
        <p className='wave-imgs-content-text1'>
        Regular compatibility checks across various devices, operating systems, and browsers to ensure a seamless user experience for all users.</p>

<p className='wave-imgs-content-text1'>Swift adaptation to new platform updates and technological advancements to maintain optimal compatibility and performance.</p>
      
      </div>

      
    </div>
    <div className="app-container">
      

      <div >
        <h2 className='wave-imgs-content-head1'>Continuous Technical
        Support
</h2>
        <p className='wave-imgs-content-textright'>
        A dedicated technical support team is available round-the-clock to address any queries, concerns, or technical issues promptly.</p>

<p className='wave-imgs-content-textright'>Personalized assistance and troubleshooting guidance to empower you with the knowledge and resources needed to navigate potential challenges effectively.


</p>
      
      </div>
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={continuous} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      
    </div>
    <div className="app-container">
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={dedicated} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      <div >
        <h2 className='wave-imgs-content-head'>Dedicated Account
        Management</h2>
        <p className='wave-imgs-content-text1'>
        Assigned account managers as your primary point of contact, ensuring clear communication, smooth coordination, and personalized attention.</p>

<p className='wave-imgs-content-text1'>Regular progress updates, performance reports, and strategic recommendations to keep you informed and involved in the maintenance process.</p>
      
      </div>

      
    </div>
    <div className="app-container">
      

      <div >
        <h2 className='wave-imgs-content-head1'>Data Analytics &
        Insights
</h2>
        <p className='wave-imgs-content-textright'>
        Utilization of advanced analytics tools to track user behavior, engagement metrics, and performance indicators.</p>

<p className='wave-imgs-content-textright'>In-depth analysis of data patterns and trends to derive actionable insights for informed decision-making and strategic planning.
</p>
<p  className='wave-imgs-content-textright'>Data-driven recommendations for optimizing user experience, enhancing feature usability, and maximizing app effectiveness.</p>
      
      </div>
      <div className="app-image-section">
        <div className="app-image-box">
          <img src={data} alt="Placeholder" className="img-fluid" /> 
        </div>
      </div>

      
    </div>
    

          <div className="web-appli">
          <div className="web-appli-head">
          Why Choose Us?
          </div>
          <div className="web-appli-contents">
            <div className="application-box">
              <div className="one">1</div>
              <div className="one-head">
              <Link to="">Expertise</Link>
              </div>
              <div className="one-con">
              Our team comprises seasoned professionals with years of experience in app development and maintenance. We bring unparalleled expertise to every project, ensuring your app stays ahead in the ever-evolving digital landscape.


              </div>
               <img className='one-img' src={Expertise} alt="expertise" style={{width:'30vw', height:'25vw'}}/> 
              <div className="uparrow"><Link><FiArrowUpRight /></Link></div>
            </div>
            <div className="application-box">
              <div className="one">2</div>
              <div className="one-head">
              <Link to="">Proactive Monitoring</Link>
              </div>
              <div className="one-con">
We believe in staying ahead of potential issues. Our proactive monitoring systems keep a vigilant eye on your app's performance, swiftly identifying and resolving any anomalies before they escalate into major problems.

</div>
              <img className='one-img' src={Proactive} alt="proactive" style={{width:'30vw', height:'25vw'}}/> 
              <div className="uparrow"><Link><FiArrowUpRight /></Link></div>
            </div>
            <div className="application-box">
              <div className="one">3</div>
              <div className="one-head">
              <Link to="">Updates & Optimization
</Link>
              </div>
              <div className="one-con">We believe in staying ahead of potential issues. Our proactive monitoring systems keep a vigilant eye on your app's performance, swiftly identifying and resolving any anomalies before they escalate into major problems.</div>
              <img className='one-img' src={Regular} alt="regular" style={{width:'30vw', height:'25vw'}} /> 
              <div className="uparrow"><Link><FiArrowUpRight /></Link></div>
            </div>
            <div className="application-box">
              <div className="one">4</div>
              <div className="one-head">
                <Link to="">Security & Compliance</Link>
              </div>
              <div className="one-con">Your app's security is our top priority. We implement robust security measures and ensure compliance with relevant regulations, safeguarding your users' data and your brand reputation.
              </div>
              <img className='one-img' src={securitycompliance} alt="securitycompliance" style={{width:'30vw', height:'25vw'}}/>
              <div className="uparrow"><Link><FiArrowUpRight /></Link></div>
            </div>
            <div className="application-box">
              <div className="one">5</div>
              <div className="one-head">
              <Link to="">Scalability</Link>
              </div>
              <div className="one-con">As your app grows, so do its demands. Our scalable support services seamlessly accommodate your app's evolving needs, ensuring it can handle increased traffic, user base, and feature enhancements without skipping a beat.</div>
               <img className='one-img' src={Scalability} alt="Scalability" style={{width:'30vw', height:'25vw'}}/> 
              <div className="uparrow"><Link><FiArrowUpRight /></Link></div>
            </div>
            <div className="contact-con">
        <div className="contact-h">
        Contact us today to learn more and
schedule a consultation
        </div>
        <div className="contact-p">Let's ensure your app continues to shine in the digital realm!</div>
      </div>
      <ContactFrom/>
          </div>
        </div>
    </div>
  )
}

export default Maintenance