import React from 'react'
import '../../../css/salemanagement.css'
import productivity from '../../../assets/images/Productivity_odoo.png'
import ClientLogohead from '../../Home/ClientLogohead'
import Discuss from '../../../assets/images/Discuss.png'
import approvel from '../../../assets/images/Approvel.png'
import ContactFrom from '../../Services/ContactFrom'
import { Helmet } from 'react-helmet';

function Productivity() {
  const productivity="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599931/rio_main_website/jjxqr56ialkvlab8gulu.png"
  const Discuss="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599907/rio_main_website/bfr3yorwjwhed5lm0zjd.png"
  const approvel="https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599894/rio_main_website/posciqcebdndsatiutu1.png"
  return (
    <div>
        <Helmet>
        <title>Odoo ERP Productivity Solutions - Rio Bizsols Pvt Ltd
        </title>
        <meta name="description" content="Enhance business productivity with Rio Bizsols Pvt Ltd's Odoo ERP solutions. Optimize workflows and streamline processes for increased efficiency.
" />
        <meta name="keywords" content="" />
      </Helmet>
       <div className="sale_container_bg">
      <div className="sale_banner">
      <img src={productivity} alt="productivity" className='sale_banner_img'/>
      <div className="sale_text_col">
      <h1 className='sale_banner_title'>Experience the Future of
      Business with RIO</h1>
      <h3 className='sale_banner_subtitle'>Your Partner in Cutting-Edge Odoo ERP Solutions.</h3>
                   <p>As a premier Odoo ERP development company, RIO offers comprehensive Odoo productivity solutions designed to enhance your team’s efficiency, streamline communication, and optimize approval processes. Leveraging the powerful features of Odoo, we provide tailored solutions that empower you to boost productivity and collaboration within your organization.</p>     
        </div>
        </div>

      </div>
      <ClientLogohead/>
      <div className='container'>
        <hr/>
      </div>
      <div className="container">
        <div className="sale_banner1">
        <img src={Discuss} alt="discuss"  className='sale_image1' />
        <div className="sale_text_col1">
          <h2 className='sale_title1'>Discuss</h2>
          <p>Enhance team communication and collaboration with Odoo Discuss. Enable real-time messaging, group chats, and discussion channels to facilitate seamless information sharing and coordination across departments.</p>
          <p>Whether communicating with colleagues using real-time messaging or organizing conversations and ensuring relevant information is easily accessible to team members using group chats and channels or sharing files, documents, and attachments within chat conversations, everything is done effortlessly with RIO.</p>
        </div> 
        </div>
        </div>
        <div className='container'>
        <hr/>
      </div>
      <div className="container">
        <div className="sale_banner1">
        <img src={approvel} alt="approvel"  className='sale_image1' />
        <div className="sale_text_col1">
          <h2 className='sale_title1'>Approvals</h2>
          <p>Streamline approval processes with Odoo Approvals. Automate and manage approval requests for various business operations, from expense reports to leave requests, ensuring timely and efficient decision-making.</p>
          <p>Design custom approval workflows that fit your business processes. Define approval hierarchies, set up sequential or parallel approvals, and specify automatic approval or rejection conditions. Keep approvers informed with automated notifications and reminders. Ensure timely action on approval requests to prevent delays and bottlenecks.</p>
        </div> 
        </div>
        </div>
        
        <div className="web_bg">
            <h2 className='web_bg_title'>With a track record of successful
            implementations and satisfied clients</h2>
            <p className='web_bg_para'>RIO is trusted by businesses worldwide to deliver exceptional Odoo solutions.

Don't just take our word for it—hear directly from our satisfied clients.</p>
        </div>
        <div>
            <h3 className='web_contact_title'>Start your business growth with us</h3>
            <p className='web_contact_para'>Ready to transform your business with RIO's Odoo development services?</p>
            <p className='web_contact_para1'>Contact us today for a free consultation and discover how we can help you achieve your business goals.</p>
            <ContactFrom/>
        </div>      
    </div>
  )
}


export default Productivity