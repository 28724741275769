import React from 'react'
import '../../../css/seo.css'
import { BiCheck } from "react-icons/bi";
import ClientLogohead from '../../Home/ClientLogohead';
import Payperaccordion from '../../Accordion/Payperaccordion';
import ContactFrom from '../../Services/ContactFrom';
import {Link} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Seo() {

  const seobanner = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599935/rio_main_website/opoxg6kktfvotg6k2xaw.png"
  const high = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599915/rio_main_website/wpr3dalihevt0aaea7ed.png"
  const quality = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599915/rio_main_website/xbzw7hy1qeeyfa7rs2tt.png"
  const logo = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599934/rio_main_website/tqcjdfdwuc1e0fntiqwb.png"
  const user = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599948/rio_main_website/gc2l7slb5vwnjnsdkfha.png"
  const trust = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599903/rio_main_website/eufcxjpzfzx1ybmmeq0k.png"
  const stay = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599940/rio_main_website/hscln8weghvxn4rbvrnb.png"
  const cost = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599902/rio_main_website/jlivfxhzuenngkaocfi8.png"
  const long = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599926/rio_main_website/kcamnnxm9kfpssajrof0.png"
  const seo = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599936/rio_main_website/n2qryc87lidediv8cd79.png"
  const keyword = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599922/rio_main_website/r3p2uz6y0vbflse3v4wn.png"
  const seoon = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599936/rio_main_website/ome3lhykpwxins29pign.png"
  const techseo = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599935/rio_main_website/xhjrz9zosi8gfdijnr6m.jpg"
  const content = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599900/rio_main_website/tskacf9gkbdw6kogspgf.png"
  const linkbulid = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599924/rio_main_website/v3jfug5wzvlnf1t4m4ke.png"
  const localseo = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599925/rio_main_website/dsmqeudf8e9nl0xgqptz.png"
  const seochoose = "https://res.cloudinary.com/dhzg3dnfc/image/upload/v1732599936/rio_main_website/w4tw5x28up8g29wpj3sv.png"

  const accordionItems = [
    { label: "Expertise and Experience", content: "With years of experience in the SEO industry, our team of experts stays ahead of the curve, continuously updating our techniques to align with the latest search engine algorithms and industry best practices. We pride ourselves on delivering results that matter." },
    { label: "Tailored Strategies", content: "We understand that every business is unique. Our SEO strategies are customized to meet your specific needs and goals. Whether you’re looking to increase local visibility, boost e-commerce sales, or improve overall search rankings, we have the expertise to craft a solution that works for you." },
    { label: "Proven Results", content: "Our track record speaks for itself. We have helped numerous clients achieve top rankings on search engines, resulting in increased traffic, higher conversion rates, and significant revenue growth." },
    { label: "Transparency and Communication", content: "We believe in maintaining open and honest communication with our clients. You’ll receive regular updates and detailed reports to keep you informed of our progress and the results of our efforts." },
    { label: "Holistic Approach", content: "Our holistic approach to SEO ensures that every aspect of your online presence is optimized for success. From technical audits to content strategy, we leave no stone unturned in our quest to boost your search engine performance." },
    
  ];
  return (
    <div>
        <Helmet>
        <title>Expert SEO Services - Rio Bizsols Pvt Ltd </title>
        <meta name="description" content="Rio Bizsols Pvt Ltd offers comprehensive SEO services including eCommerce, lead generation, local SEO, and more. Grow your online presence today!
" />
        <meta name="keywords" content="seo companies,seo agency,search engine optimization company, SEO Services" />
      </Helmet>
        <div className="brand_bg">
        <div className="brand_container">
        <img src={seobanner} alt="seobanner"  />
        <div className="seo_text_column">
        <h1 className='seo_banner_title'>
        Why Settle for the Ordinary When You Can Be Extraordinary?
        </h1>
        <p className='seo_banner_descr'>
        Unlock the power of SEO and transform your business’s digital presence with RIO’s SEO services. We specialize in delivering top-tier SEO services designed to elevate your brand, drive organic traffic, and maximize your ROI. Our comprehensive SEO strategies ensure your business stands out from the competition and reaches its full potential online.
        </p>

<Link to='/contact'  style={{textDecoration:'none', color:'White'}}>
<button className='content_button'>Let's connect <BiCheck className='ipr_icon1'/>
</button>
</Link>
        </div>
          </div>  
      
      </div>
         {/* <div className="seo_banner_bg">
            <div className="seo_container">
                <div className="seo_text_column">
                <img src={seobanner} alt="" className='sm_banner_img' />
                </div>
                <div className="seo_text_column">
                    <h1 className='seo_banner_title'>
                    With Premier SEO
                    ServicesWith Premier SEO Services
                    </h1>
                    <p className='seo_banner_descr'>
                    Unlock the power of SEO and transform your business’s digital presence with RIO’s SEO services. We specialize in delivering top-tier SEO services designed to elevate your brand, drive organic traffic, and maximize your ROI. Our comprehensive SEO strategies ensure your business stands out from the competition and reaches its full potential online.
                    </p>
                    <button className='content_button'>Let's connect <BiCheck className='content_icon1'/></button>
                </div>
            </div>
        </div>  */}
        <ClientLogohead/>
        <div className="container">
        <hr className='b-line' />
        </div>
        <h2 className='seo_title'>
        Why invest in Search Engine Optimization?
        </h2>
        <p className='seo_txt_description'>
        By investing in SEO, you are investing in the long-term success and growth of your business. Here’s why SEO (Search Engine Optimization) is indispensable for your digital presence -
        </p>
        <section className='seo_wapp'>
            <div className="black seo-grid">
           <img src={high} alt="high" /> 
          <h4 className='seo-grid-title'>Boost Your Online Visibility</h4>
          <p className='seo-grid-description'>In a digital-first world, the majority of consumers turn to search engines to find products and services. SEO ensures your business appears prominently in these search results, increasing the likelihood that potential customers will find and visit your site.</p>
          </div>
          <div className="black seo-grid">
          <img src={quality} alt="quality" /> 
       
          <h4 className='seo-grid-title'>Drive High-Quality Traffic</h4>
          <p  className='seo-grid-description'>SEO targets users who are actively searching for keywords related to your business. This means the traffic driven to your site is more likely to convert, as these visitors are already interested in what you have to offer.</p>
          </div>

            </section>
            <img src={logo} alt='logo' className='seo_center_logo'/>
            <section className='seo_wapp'>
            <div className="black seo-grid">
           <img src={user} alt="user" /> 
          <h4 className='seo-grid-title'>Enhance User Experience</h4>
          <p className='seo-grid-description'>Good SEO practices improve the overall user experience on your site. From faster loading times to mobile-friendly design, SEO ensures that visitors have a positive experience, which can lead to higher engagement and better conversion rates.</p>
          </div>
          <div className="black seo-grid">
           <img src={trust} alt="trust" />  
          
          
          <h4 className='seo-grid-title'>Build Credibility and Trust</h4>
          <p  className='seo-grid-description'>Websites that rank high on search engine results pages (SERPs) are often perceived as more credible and trustworthy. Effective SEO helps establish your brand’s authority, encouraging users to trust your business over competitors.</p>
          </div> 

            </section>
            <section className='seo_wapp'>
            <div className="black seo-grid">
            <img src={stay} alt="stay" />  
          <h4 className='seo-grid-title'>Stay Ahead of the Competition</h4>
          <p className='seo-grid-description'>In competitive markets, businesses with strong SEO strategies gain a significant advantage. By optimizing your site for search engines, you can stay ahead of competitors who are not leveraging SEO, capturing a larger share of your target market.</p>
          </div>
          <div className="black seo-grid">
          <img src={cost} alt="cost" />   
          <h4 className='seo-grid-title'>Cost-Effective Marketing</h4>
          <p  className='seo-grid-description'>Compared to traditional advertising methods, SEO is highly cost-effective. It targets users who are actively searching for your products or services, making it a smart investment that can yield high returns over time.</p>
          </div>

            </section>
            <img src={logo} alt='logo' className='seo_center_logo'/>
            <section className='seo_wapp'>
            <div className="black seo-grid">
            <img src={long} alt="long" />  
          <h4 className='seo-grid-title'>Long-Term Results</h4>
          <p className='seo-grid-description'>Unlike paid advertising, which stops delivering results once the budget runs out, SEO provides long-lasting benefits. Once your site achieves high rankings, it can maintain its position with ongoing optimization, continually driving traffic and leads.</p>
          </div>
          <div className="black seo-grid">
          <img src={seo} alt="seo" />   
          <h4 className='seo-grid-title'>Adaptability and Measurability</h4>
          <p  className='seo-grid-description'>SEO is highly adaptable and measurable. With analytics tools, you can track your progress, see what’s working, and make data-driven decisions to refine your strategy continually. This adaptability ensures that your SEO efforts remain effective and aligned with your business goals.</p>
          </div>

            </section>
             <h2 className='seo_service_title'>Services We Offer</h2>

             <div className='staff_bg'>
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={keyword} alt="keyword"  className='staff_img_top' />
            <div className='staff_card'>
            <h2 className='staff_card_head'>Keyword Research and Analysis</h2>
            <p>We conduct thorough market research to identify the most effective keywords to drive targeted traffic to your site, ensuring your content aligns with your potential customers' searches.</p>
           
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={seoon} alt="seo"  className='staff_img1' />
            
            <div className="staff_card_right">
              <h2 className='staff_card_head'>On-Page Optimization</h2>
              <p>Our team enhances your website’s structure, content, and meta tags to improve search engine rankings and user experience, making sure every page is fully optimized for maximum visibility.</p>
             
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={techseo} alt="techseo"  className='staff_img' />
            <div className='staff_card'>
            <h2 className='staff_card_head'>Technical SEO</h2>
            <p>We ensure your website is technically sound with optimal loading speeds, mobile responsiveness, and secure HTTPS connections, addressing any technical issues that could hinder your site's performance.</p>
            
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={content} alt="content"  className='staff_img1' />
            
            <div className="staff_card_right">
              <h2 className='staff_card_head'>Content Creation and Marketing</h2>
              <p>We develop high-quality, engaging content that attracts and retains visitors, boosting your site's relevance and authority in search engines through strategic content marketing.</p>
              
            </div>
           
            </Col>
          </Row>
        </div> 
        <div className="container">
          <Row>
            <Col sm={9}>
            <img src={linkbulid} alt="linkbulid"  className='staff_img' />
            <div className='staff_card'>
            <h2 className='staff_card_head'> Link Building</h2>
            <p>Our link-building strategies focus on building a robust backlink profile to enhance your site’s authority and credibility through strategic outreach and partnerships with reputable websites.</p>
            
            </div>
            </Col>
            <Col sm={3}> 
            </Col>
          </Row>
        </div>
      
        <div className="container">
          <Row>
            <Col sm={1}>
            
            </Col>
            <Col sm={11}> 
            <img src={localseo} alt="localseo"  className='staff_img_bottom' />
            
            <div className="staff_card_right">
              <h2 className='staff_card_head'>Local SEO</h2>
              <p>We boost your visibility in local search results to attract more customers in your area by optimizing for local search terms, managing local listings, and encouraging positive reviews.</p>
             
            </div>
           
            </Col>
          </Row>
        </div>     
        </div>
          
            <h2 className='seo_service_title'>Why Choose Us?</h2>
            <div className="seo_container">
              <img src={seochoose} alt="seochoose" className='seo_choose_img'/>
              <div className='seo_text_column'>
             <div className='seo_accordion'>
             
             <Payperaccordion items={accordionItems} />
             </div>
              </div>
              </div>
              <h2 className='pay_title2'>Let’s Discuss Your Project</h2>
              <p className='pay_txt_description'>Ready to take your online presence to the next level? Partner with RIO to experience the transformative power of expert SEO. Let’s start building a strategy that will drive your business forward.</p>
       <ContactFrom/>
    </div>
  )
}

export default Seo